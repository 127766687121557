import MediaPreviewCom from "@/components/mediaPreviewCom.vue";
import showModal from "@/utils/modalUtil";

function showMediaPreview(value){
    if (typeof(value) === "string"){
        value = {"url":value, "type": "image"};
    }
    const {url, type} = value;
    showModal(MediaPreviewCom, {"src": url, type});
}

export default  {
    beforeMount(el, {value}) {
        el._meidaPreviewHandler = () => showMediaPreview(value);
        el.addEventListener("click", el._meidaPreviewHandler);
    },
    beforeUnmount(el) {
        el.removeEventListener("click", el._meidaPreviewHandler);
    },
};
