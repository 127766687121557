<template>
    <transition name="viewer-fade" appear>
        <div class="media-preview el-image-viewer__wrapper">
            <div class="el-image-viewer__mask" @click="onClickClose" />
            <span class="el-image-viewer__btn el-image-viewer__close" @click="onClickClose"><el-icon><Close /></el-icon></span>
            <video
                v-if="type==='video'"
                class=""
                controls
                :src="src"
            />
            <img v-if="type==='image'" :src="src">
        </div>
    </transition>
</template>

<script setup>
import {ElIcon} from "element-plus";
import {Close} from "@element-plus/icons-vue";

defineProps({"src":String, "type": String});
const emit = defineEmits(["cancel", "closed"]);
function onClickClose(){
    emit("cancel");
    emit("closed");
}
</script>
<style scoped>
    .media-preview {display: flex; align-items: center; justify-content: center; z-index: 1;}
    .media-preview > img, .media-preview > video {max-width: 100%; z-index: 100;max-height: 90%;}
    .el-image-viewer__close {z-index: 999;}
</style>