import {createApp} from "vue";
import {createPinia} from "pinia";
import App from "./App.vue";
import router from "@/router";
import ElementPlus from "element-plus";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import "element-plus/dist/index.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

const app = createApp(App);

app.use(createPinia());
app.use(ElementPlus, {"size": "large", "zIndex": 3000, "locale": zhCn});

app.use(router);
app.mount("#app");

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}

import MediaUploadCom from "@/components/mediaUploadCom.vue";
// import AudioPlayer from "@/components/audioPlayerCom.vue";
// import AudioRecord from "@/components/audioRecordCom.vue";
app.component("MediaUpload", MediaUploadCom);
// app.component("audio-record", AudioRecord);
// app.component("media-player", AudioPlayer);


import VMediaPreviewDirective from "@/components/mediaPreviewDirective";
app.directive("media-preview", VMediaPreviewDirective);

import FormDialog from "@/components/formDialog.vue";
app.use(FormDialog);