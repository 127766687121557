<template>
    <router-view v-if="!errMSG" v-slot="{Component}" class="route-page">
        <keep-alive include="HomeworkPanelView">
            <component :is="Component" />
        </keep-alive>
    </router-view>

    <el-result v-else icon="error" title="应用打开失败">
        <template #sub-title>
            <p v-for="l in errMSG.split('\n')" class="row__msg">
                <span v-if="!l.includes('-')">{{ l }}</span>
                <template v-else>
                    <span class="field">{{ l.split('-')[0] }}</span>
                    <span>{{ l.split('-').slice(1).join('-') }}</span>
                </template>
            </p>
        </template>
    </el-result>
</template>

<script setup>
import {ref, onMounted} from "vue";
import dd from "dingtalk-jsapi";
import useBaseinfoStore from "@/store/baseinfoStore";
import useAccountInfo from "@/store/accountStore";

const baseinfoStore = useBaseinfoStore();
const accountStore = useAccountInfo();
const errMSG = ref("");
const accountInfo = ref(null);
onMounted(async function () {
    try{
        const queryParams = new URLSearchParams(location.search);

        const token = queryParams.get("token");
        const corpId = queryParams.get("corpid");
        if (token){
            // 学在青田Token登录
            accountInfo.value = await accountStore.loginXZQT(token);
            if (accountInfo.value){
                location.replace("/");
                return;
            }
        } else if (corpId && dd.env.platform !== "notInDingTalk"){
            // 学在青田钉钉登录
            // TODO 可以进一步区分是否原始钉钉登录，但是没必要
            if (dd.version < "7") {
                // 存在js兼容性问题
                errMSG.value = "当前钉钉版本过低，请更新客户端后使用";
                return;
            }
            const {code} = await dd.runtime.permission.requestAuthCode({corpId});
            const {data, msg} = await accountStore.loginDingtalk(code, corpId);
            if (!data){
                errMSG.value = msg;
            }else {
                accountInfo.value = data;
                location.replace("/");
            }
            return;
        }

        if (!accountInfo.value){
            accountInfo.value = await accountStore.getAccountInfo();
        }
        if (!accountInfo.value) {
            errMSG.value = "请在钉钉工作台搜索【作业控量管理】打开";
            return;
        }
        if (!accountInfo.value.roleList?.length){
            errMSG.value = "当前用户没有关联的角色，请联系管理员";
            return;
        }
        const schoolRole = accountInfo.value.roleList.find(r => r.schoolId&&r.role !== "家长");
        if (schoolRole){
            accountStore.schoolClassList = await baseinfoStore
                .getClassList(schoolRole.role, schoolRole.schoolId);
        }
        const eduRole = accountInfo.value.roleList.find(r => r.eduBureauId);
        if (eduRole){
            accountStore.schoolList = await baseinfoStore.getSchoolList(eduRole.eduBureauId);
        }
    } catch(e){
        errMSG.value = e.toString();
        console.error(e);
    }
});
</script>
<style>
* {box-sizing: border-box; font-family: 'Arial Negreta', 'Arial Normal', 'Arial', sans-serif; }
html, body, #app, .route-page {width: 100%; height: 100%; margin:0;padding:0;background-color: var(--el-bg-color-page);}
.route-page {display: flex; flex-direction: column;}
a{text-decoration: none; color: var(--el-menu-text-color); text-decoration: none;}

.el-form-item__content .el-select, .el-form-item__content .el-select-v2, .el-form-item__content .el-cascader, .el-form-item__content .el-date-editor.el-input{width: 100%;}
@media screen and (min-width: 960px) {
    body{
        width: 960px;
        margin: auto;
    }
}
</style>
<style scoped>
    .row__msg{
        text-align: left;
        display: flex;
    }
    .row__msg span {
        display: inline-block;
    }
    .field{
        min-width: 6em;
        max-width: 8em;
        margin-right: 6px;
    }
</style>
